import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs, EffectCards } from 'swiper'
import { media } from './functions/media'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs, EffectCards])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export const createStandSlider = (): void => {
  const standsBullets = new window.Swiper('.stands-bullets .swiper', {
    direction: 'horizontal',
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 20,
    speed: 500,
    grabCursor: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 4,
      },
      [media.xl]: {
        direction: 'vertical',
        slidesPerView: 'auto',
      },
    },
  }) as Swiper

  new window.Swiper('.stands-slider .swiper', {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,
    speed: 500,
    grabCursor: true,
    thumbs: {
      swiper: standsBullets,
    },
  }) as Swiper
}

export default (): void => {
  new window.Swiper('.advantages-slider-cards .swiper', {
    effect: 'cards',
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 32,
    speed: 800,
    grabCursor: true,
    loop: true,
    watchOverflow: true,
    cardsEffect: {
      perSlideOffset: 6,
      rotate: false,
      slideShadows: false,
    },
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.contacts-slider .swiper', {
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 24,
    speed: 500,
    grabCursor: true,
    watchOverflow: true,
    breakpoints: {
      [media.md]: {
        slidesPerView: 1.7,
      },
      [media.lg]: {
        slidesPerView: 3,
      },
      [media.xl]: {
        slidesPerView: 4,
        allowTouchMove: false,
      },
    },
  }) as Swiper

  new window.Swiper('.advantages-slider .swiper', {
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 28,
    speed: 500,
    grabCursor: true,
    watchOverflow: true,
    breakpoints: {
      [media.md]: {
        slidesPerView: 1.7,
      },
      [media.md]: {
        slidesPerView: 1.8,
      },
      [media.lg]: {
        slidesPerView: 2.3,
      },
      [media.xl]: {
        slidesPerView: 3,
        allowTouchMove: false,
      },
    },
  }) as Swiper
}
