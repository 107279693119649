export default (): void => {
  const status: boolean = sessionStorage.getItem('admin') === 'active'
  const navItems = document.querySelectorAll('*[data-admin-nav]') as NodeListOf<Element>
  const auth = document.querySelector('*[data-admin-auth]') as HTMLElement
  const content = document.querySelector('*[data-admin-content]') as HTMLElement

  switch (status) {
  case true: {
    if (auth) auth.remove()
    break
  }

  case false: {
    navItems.forEach((element: Element): void => {
      const navItem = element as HTMLElement

      if (navItem) navItem.remove()
    })

    if (content) content.remove()
    break
  }
  }

  document.addEventListener('click', ((event: Event): void => {
    if ((event.target as HTMLButtonElement).hasAttribute('data-admin-remove')) {
      const image = (event.target as HTMLButtonElement).closest('[data-admin-image]') as HTMLElement

      image.remove()
    }
  }) as EventListener)
}
