import { dialogOpen, dialogNotClosing, dialogClose } from './fancybox'
import { validation } from './functions/validation'

const submitHandler = (event: Event, data: File[]): void => {
  const form = event.target as HTMLFormElement

  switch (form.dataset.form) {
  case 'action': {
    if (!validation(form)) event.preventDefault()
    break
  }

  default: {
    event.preventDefault()

    if (!validation(form)) return

    const formData: FormData = new FormData(form)
    const submitBtn = form.querySelector('button[type="submit"]') as HTMLButtonElement
    const requestUrl = './ajax/submit-handler.php'

    if (form.hasAttribute('data-form-files')) {
      if (data !== null) for (let i = 0; i < data.length; i++) formData.append('files[]', data[i])
    }

    submitBtn.setAttribute('disabled', 'disabled')
    dialogNotClosing('./dialogs/dialog-preloader.php')

    fetch(requestUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response: Response): Promise<any> => {
        return form.dataset.form !== 'admin' ? response.json() : response.text()
      })
      .then((response: Response): void => {
        switch (form.dataset.form) {
        case 'submit': {
          dialogClose()

          switch (response.status) {
          case true: {
            dialogOpen('./dialogs/dialog-success.php')
            break
          }

          case false: {
            dialogOpen('./dialogs/dialog-error.php')
            break
          }
          }

          break
        }

        case 'auth': {
          setTimeout((): void => {
            if (response.status) {
              sessionStorage.setItem('admin', 'active')
              window.location.reload()
            }

            dialogClose()
          }, 1000)

          break
        }

        case 'admin': {
          if (sessionStorage.getItem('admin') && sessionStorage.getItem('admin') === 'active')
            setTimeout((): void => window.location.reload(), 1000)
          break
        }
        }

        form.reset()
        submitBtn.removeAttribute('disabled')

        if (form.hasAttribute('data-form-files')) {
          const listing = form.querySelector('*[data-files-listing]') as HTMLElement
          const text = form.querySelector('*[data-files-text]') as HTMLElement

          listing.innerHTML = ''
          listing.classList.remove('mb-5')
          text.innerHTML = 'Загрузить файлы'
          data.length = 0
        }
      })
      .catch((error: string): void => console.log('The form has not been sent', error))

    break
  }
  }
}

export default (data: File[]): void => {
  document.addEventListener('submit', ((event: Event): void => {
    if ((event.target as HTMLFormElement).hasAttribute('data-form')) submitHandler(event, data)
  }) as EventListener)
}
