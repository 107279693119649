import { media } from './functions/media'
import { scrolledPage } from './functions/scrolled-page'

export default (): void => {
  const scrolling = document.querySelector('*[data-scrolling]') as HTMLElement

  if (!scrolling) return

  const header = document.querySelector('*[data-header]') as HTMLElement
  const top = document.querySelector('*[data-scrolling-top]') as HTMLElement
  const buttons = scrolling.querySelectorAll('*[data-scrolling-button]') as NodeListOf<Element>
  const blocks = scrolling.querySelectorAll('*[data-scrolling-block]') as NodeListOf<Element>

  const statusCurrentBlock = (): void => {
    switch ((document.documentElement as HTMLElement).clientWidth > media.lg) {
    case true: {
      switch (header.classList.contains('lg:-translate-y-full')) {
      case true: {
        top.style.top = '16px'
        break
      }

      case false: {
        top.style.top = `${header.offsetHeight + 16}px`
        break
      }
      }

      break
    }

    case false: {
      top.style.top = `${header.offsetHeight + 16}px`
      break
    }
    }

    blocks.forEach((element: Element): void => {
      const block = element as HTMLElement

      const currentScroll: number = scrolledPage().top - block.offsetTop
      const offsetHeight: number = block.offsetHeight
      const result: number = (currentScroll * 100) / offsetHeight
      let percent = 0

      if (result < 0) {
        percent = 0
      } else if (result > 100) {
        percent = 100
      } else {
        percent = result
      }

      buttons.forEach((item: Element): void => {
        const button = item as HTMLElement

        if (button.dataset.scrollingButton === block.dataset.scrollingBlock)
          button.style.setProperty('--scroll-width', `${percent}%`)
      })
    })
  }

  statusCurrentBlock()

  document.addEventListener('scroll', statusCurrentBlock as EventListener)
}
