const sortingShow = (condition: boolean, item: HTMLElement): void => {
  switch (condition) {
  case true: {
    item.classList.remove('hidden')
    item.classList.add('sorting-show')
    setTimeout((): void => item.classList.remove('sorting-show'), 300)
    break
  }

  case false: {
    item.classList.add('hidden')
    break
  }
  }
}

const currentCard = (currentValues, cards: NodeListOf<Element>, notification: HTMLElement): void => {
  let hiddenCards = 0

  cards.forEach((element: Element): void => {
    const card = element as HTMLElement
    const cardValue: string[] = String(card.dataset.sortingCard).split(' ')
    const currentValuesArray = Object.values(currentValues)
    const dataCard = cardValue.sort().join(',')
    const dataToggles = currentValuesArray.sort().join(',')

    const checkingValues = (): boolean => {
      let result = false

      cardValue.filter((item: string) => {
        if (currentValuesArray.includes(item)) result = currentValuesArray.includes(item)
      })

      return result
    }

    const value: boolean = currentValuesArray.includes('all') && checkingValues()

    sortingShow(dataCard === dataToggles || value, card)

    if (dataCard !== dataToggles && !value) ++hiddenCards
  })

  sortingShow(cards.length === hiddenCards, notification)
}

export default (): void => {
  const sorting = document.querySelector('*[data-sorting]') as HTMLElement

  if (!sorting) return

  const toggles = document.querySelectorAll('*[data-sorting-toggle]') as NodeListOf<Element>
  const cards = document.querySelectorAll('*[data-sorting-card]') as NodeListOf<Element>
  const notification = document.querySelector('*[data-sorting-notification]') as HTMLElement
  const currentValues = {}

  const checkedToggles = (toggle: HTMLInputElement): void => {
    if (toggle.checked === true) {
      currentValues[toggle.name] = toggle.value
      currentCard(currentValues, cards, notification)
    }
  }

  toggles.forEach((element: Element): void => {
    const toggle = element as HTMLInputElement

    checkedToggles(toggle)

    toggle.addEventListener('change', ((): void => {
      checkedToggles(toggle)
    }) as EventListener)
  })
}
