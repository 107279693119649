import { safariOnMacbook } from './functions/safari-on-macbook'

export default (): void => {
  const inverteds = document.querySelectorAll('*[data-inverted]') as NodeListOf<Element>

  inverteds.forEach((element: Element): void => {
    const inverted = element as HTMLElement

    if (!inverted) return

    inverted.classList.add('inverted--init')

    if (!safariOnMacbook()) {
      const before = inverted.querySelector('*[data-inverted-before]') as HTMLElement
      const after = inverted.querySelector('*[data-inverted-after]') as HTMLElement
      const uppers = inverted.querySelectorAll('*[data-inverted-upper]') as NodeListOf<Element>

      before.classList.add('inverted-3d')
      after.classList.add('inverted-3d')

      uppers.forEach((item: Element): void => {
        const upper = item as HTMLElement

        upper.classList.add('inverted-3d')
      })
    }
  })
}
