import { scrolledPage } from './functions/scrolled-page'

export default (): void => {
  const header = document.querySelector('*[data-header]') as HTMLElement

  if (!header) return

  let prevOffsetTop: number = scrolledPage().top

  const scrollHeader = (): void => {
    const currentOffsetTop: number = scrolledPage().top

    switch (header.offsetHeight < currentOffsetTop) {
    case true: {
      header.classList.remove('bg-opacity-0', 'dark:bg-opacity-0')
      header.classList.add('header--shadow', 'bg-opacity-70', 'dark:bg-opacity-70')

      prevOffsetTop > currentOffsetTop
        ? header.classList.remove('lg:-translate-y-full')
        : header.classList.add('lg:-translate-y-full')

      break
    }

    case false: {
      header.classList.add('bg-opacity-0', 'dark:bg-opacity-0')
      header.classList.remove('header--shadow','bg-opacity-70', 'dark:bg-opacity-70')
      break
    }
    }

    prevOffsetTop = currentOffsetTop
  }

  scrollHeader()

  document.addEventListener('scroll', scrollHeader as EventListener)
}
