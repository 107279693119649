import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import yandexMap from './ts/yandex-map'
import adminPanel from './ts/admin-panel'
import accordion from './ts/accordion'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import currentYear from './ts/current-year'
import sidebar from './ts/sidebar'
import scrollTo from './ts/scroll-to'
import theme from './ts/theme'
import animation from './ts/animation'
import waved from './ts/waved'
import inverted from './ts/inverted'
import scrolling from './ts/scrolling'
import sorting from './ts/sorting'
import listing from './ts/listing'
import downloadFiles from './ts/download-files'
import submitHandler from './ts/submit-handler'
import dragAndDrop from './ts/drag-and-drop'
import choiceFile from './ts/choice-file'
import inputs from './ts/inputs'
import phoneMask from './ts/phone-mask'
import password from './ts/password'
import preloader from './ts/preloader'

import './scss/style.scss'

const initialization = (): void => {
  fancybox()
  sliderSwiper()
  yandexMap()
  adminPanel()
  accordion()
  currentTab()
  currentYear()
  scrollHeader()
  sidebar()
  scrollTo()
  theme()
  animation()
  waved()
  inverted()
  scrolling()
  sorting()
  listing()
  submitHandler(downloadFiles())
  dragAndDrop()
  choiceFile()
  inputs()
  phoneMask()
  password()
  preloader()
}

window.addEventListener('DOMContentLoaded', initialization as EventListener)
