import { media } from './functions/media'
import { scrolledPage } from './functions/scrolled-page'

const scrollTo = (event: Event): void => {
  event.preventDefault()

  const link = event.target as HTMLAnchorElement
  const html = document.documentElement as HTMLElement
  const id = String(link.getAttribute('href'))
  const block = document.querySelector(id) as HTMLElement
  const header = document.querySelector('*[data-header]') as HTMLElement
  const offsetTop: number =
    html.clientWidth < media.lg
      ? block.getBoundingClientRect().top + scrolledPage().top - header.offsetHeight
      : block.getBoundingClientRect().top + scrolledPage().top

  const scrollingOffsetTop =
    scrolledPage().top > offsetTop && html.clientWidth > media.lg
      ? offsetTop - header.offsetHeight * 2
      : offsetTop - header.offsetHeight

  window.scrollTo({
    top: link.dataset.scroll === 'scrolling' ? scrollingOffsetTop : offsetTop,
    behavior: 'smooth',
  })
}

const scrollToSorting = (event: Event): void => {
  const label = event.target as HTMLLabelElement
  const html = document.documentElement as HTMLElement
  const header = document.querySelector('*[data-header]') as HTMLElement
  const sorting = label.closest('#sorting') as HTMLElement

  const offsetTop: number =
    html.clientWidth < media.lg
      ? sorting.getBoundingClientRect().top + scrolledPage().top - header.offsetHeight
      : sorting.getBoundingClientRect().top + scrolledPage().top

  const sortingOffsetTop =
    scrolledPage().top > offsetTop && html.clientWidth > media.lg
      ? offsetTop - header.offsetHeight * 2
      : offsetTop - header.offsetHeight

  window.scrollTo({
    top: sortingOffsetTop,
    behavior: 'smooth',
  })
}

export default (): void => {
  document.addEventListener('click', ((event: Event): void => {
    if ((event.target as HTMLElement).hasAttribute('data-scroll')) scrollTo(event)

    if ((event.target as HTMLLabelElement).hasAttribute('data-scroll-sorting')) scrollToSorting(event)
  }) as EventListener)
}
