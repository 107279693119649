import { fileHandler } from './functions/file-handler'

const choiceFile = (event: Event): void => {
  const download = (event.target as HTMLInputElement).closest('[data-label]') as HTMLElement
  const input = event.target as HTMLInputElement
  const error = download.querySelector('*[data-error]') as HTMLElement
  const image = download.querySelector('*[data-file="image"]') as HTMLImageElement

  if (!image) return

  const file = (input.files as FileList)[0] as File
  const readFile = new FileReader() as FileReader

  file ? readFile.readAsDataURL(file) : (image.src = '')

  readFile.addEventListener('loadend', ((): void => {
    if (!fileHandler(input, error)) return

    image.src = String(readFile.result)
  }) as EventListener)
}

export default (): void => {
  document.addEventListener('change', ((event: Event): void => {
    if ((event.target as HTMLInputElement).getAttribute('data-input') === 'file') choiceFile(event)
  }) as EventListener)
}
